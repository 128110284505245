.w3-theme-l5 {color:#bc0031 !important; background-color:#ebebec !important}
.w3-theme-l4 {color:#000 !important; background-color:#dcddde !important}
.w3-theme-l3 {color:#fff !important; background-color:#000 !important}
.w3-theme-l2 {color:#fff !important; background-color:#ff3e72 !important}
.w3-theme-l1 {color:#fff !important; background-color:#fd0043 !important}
.w3-theme-d1 {color:#fff !important; background-color:#aa002d !important}
.w3-theme-d2 {color:#fff !important; background-color:#970028 !important}
.w3-theme-d3 {color:#fff !important; background-color:#840023 !important}
.w3-theme-d4 {color:#fff !important; background-color:#71001e !important}
.w3-theme-d5 {color:#fff !important; background-color:#5e0019 !important}

.w3-theme-light {color:#000 !important; background-color:#ebebec !important}
.w3-theme-dark {color:#fff !important; background-color:#5e0019 !important}
.w3-theme-action {color:#fff !important; background-color:#5e0019 !important}

.w3-theme {color:#fff !important; background-color:#bc0031 !important}
.w3-text-theme {color:#bc0031 !important}
.w3-border-theme {border-color:#fff !important}

.w3-hover-theme {color:#fff !important;}
.w3-hover-theme:hover {color:#dcddde !important; background-color:#bc0031 !important}
.w3-hover-text-theme:hover {color:#bc0031 !important}
.w3-hover-border-theme:hover {border-color:#bc0031 !important}

.w3-dropdown-content { color: #bc0031 !important; background-color: #dcddde !important;}

.w3-input-theme { color:#bc0031 !important; background-color:#ebebec !important; border-color: #bc0031 !important; }

.w3-input-theme:focus { outline: none; border: 2px solid #bc0031 !important; }
.w3-input-theme:checked { color:#bc0031 !important; background-color:#bc0031 !important; }

.w3-button-theme { color:#ebebec; font-weight: bold !important; background-color:#bc0031 !important; border-color: #dcddde !important }

.w3-button-theme:focus, .w3-button-theme:active { color:#bc0031 !important; background-color:#ebebec !important; border-color: #bc0031 !important; outline-color: #bc0031 !important; }

.w3-button-theme:hover { color:#bc0031 !important; background-color:#ebebec !important; border-color: #bc0031 !important; }

.w3-button:disabled, .w3-button-theme[disabled]:hover { color:#ebebec !important; background-color:#bc0031 !important; border-color: #dcddde !important }

.w3-dropdown-content .w3-hover-theme { color: #000 !important; }

.w3-check-theme:focus, .w3-check-theme.active { outline-color: #bc0031 !important; }

.w3-button-theme { white-space: pre-line; }
