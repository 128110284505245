.student {
  background-image: url('../images/cardtemplate1.png');
}
.medewerker, .employee {
  background-image: url('../images/cardtemplate2.png');
}

.cardpreview {
  font-size:         11.5px;
  position:          relative;
  background-size:   contain;
  background-repeat: no-repeat;
  width:             335px;
  height:            221px;

  * {
    display: none;
  }

  .profilenumber {
    display: block;
    position: absolute;
    top: 130px;
    left: 30px;
  }

  .name {
    font-size: 16px;
    font-style: italic;
    display: block;
    position: absolute;
    top: 159px;
    left: 30px;

    .title, .firstname, .nameoncard {
      display: none;
    }

    .initials, .middlename, .lastname {
      display: inline;
    }

    .initials::after, .middlename::after {
      content: ' ';
    }
  }

  .cardnumber2 {
    display: block;
    position: absolute;
    top: 185px;
    left: 30px;
  }

  .cardnumber2::before {
    content: 'Pas nr./no. ';
  }

  .photo {
    display: block;
    position: absolute;
    width: 81px;
    height: 108px;
    top:  60px;
    left: 225px;
  }
}

.medewerker, .employee {
  .profilenumber::before {
    content: 'Ref.nr/no. ';
  }
}

.student {
  .profilenumber::before {
    content: 'Stud.nr/no. ';
  }
}
