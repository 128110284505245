@import 'config';
@import 'w3';
@import 'theme';
@import '@angular/cdk/overlay-prebuilt';
@import 'cardpreview';
@import 'cardinfo';
@import '../fonts/opensans/opensans.scss';

html, body {
  font-size: 14px;
  font-family: OpenSans, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: OpenSans, sans-serif !important;
}

#about, #photorequirements {
  display: block !important;
}

#menu {
  .w3-button {
    background-color: transparent !important;
  }
}

.menu-items{
  position:relative;
  z-index: -1;
}

.mobile-animate-right{
  position:relative;
  animation:animateright 0.4s;
}

@keyframes animateright{
  from{right:-70px; opacity:0;}
  to{right:0px; opacity:1;}
}

@keyframes loading {
  from { left: 0%;   }
  50%  { left: 90%; }
  to   { left: 0%;   }
}

.toosmall {
  .main {
    width: 100%;

    .content {
      display: none;
    }

    .toosmallblock {
      display: block;
    }
  }
}

.wide {
  .main {
    width: $page-width-wide;

    .content {
      display: block;
    }

    .toosmallblock {
      display: none;
    }
  }

  table#person, table#card {
    width: calc(  #{$page-width-wide} - 132px );
    max-width: $photo-max-width;
  }

  .pageButton {
     width: $button-width-wide;
  }
}

.small {
  .main {
    width: $page-width-small;
    overflow-x: hidden;

    .content {
      display: block;
    }

    .toosmallblock {
      display: none;
    }
  }

  table#person, table#card {
    width: calc(  #{$page-width-small} - 132px );
    max-width: $photo-max-width;
  }

  .pageButton {
     width: $button-width-small;
     margin-bottom: 8px;
  }
}

.warningsmall {
  .pageButton {
     width: $button-width-small;
     margin-bottom: 8px;
  }
}

#showwarning {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}

#warning {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

header {
  height: $header-height;
}

footer {
  height: $footer-height;
}

table {
  background-color: white;
}

.content {
  min-height: $content-min-height;
  padding-bottom: 48px;
  overflow-x: hidden;
}

.loading {
  max-height: 50px;
  max-width:  50px;
  position: relative;
  animation: loading 5s infinite;
}

#logo {
  max-height: 50px;
  max-width:  300px;
}

#logoicon {
  max-height: 50px;
  max-width:  50px;
}

.close {
  height: 21px;
  width: 21px;
  line-height: 21px;
  text-align: center;
}

.hide {
  display: none !important;
}

.show {
  display: inherit;
}

.showrotation {
  display: block;
  position: relative;
  width: 40px;
  height: 25px;
  margin-top: -25px;
  margin-left: 10px;
  padding: 3px;
  z-index: 999;
  background: rgba(255, 255, 255, 0.3);
}

.done {
  color: grey;
}

.pointer {
  cursor: pointer;
}

.current {
  color: blue;
  font-size: larger;
  font-weight: bold;
}

.todo {
  color: black;
}

.photo {
  width: auto;
  height: auto;
  max-width:  $photo-max-width;
  // max-height: $photo-max-height;
}

.photoButton {
  width: 312px;
  //max-width: calc( (#{$page-width}/2) - 74 );
}

.link {
  color: #bc0031;
  cursor: pointer;
}


.link:hover {
  color: #aa002d;
  text-decoration: underline;
}

footer .link {
  color: #ebebec;
}

footer .link:hover {
  color: #dcddde;
}

.error {
  color: red;
  background-color: #F2D5D5;
  font-weight: bold;
  border: thin solid red;
}

.warning {
  color: #bc0031;
  border: thin solid #bc0031;
}

.success {
  color: green;
  background-color: #D5F2D5;
  font-weight: bold;
  border: thin solid green;
}


[draggable=true] {
  -khtml-user-drag: element;
}

#myContainer {
  position: relative;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -moz-user-drag: none;
  -webkit-user-drag: none;
  -ms-user-drag: none;
}

canvas, img {
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -moz-user-drag: none;
  -webkit-user-drag: none;
  -ms-user-drag: none;
}

input[type="password"],
input[type="password"]-internal-autofill-selected,
#password,
#repeatpassword,
#password:-internal-autofill-selected,
#repeatpassword:-internal-autofill-selected {
  background-position: 97% center;
  background-repeat: no-repeat;
  background-size: 20px;
}

#password.visible,
#repeatpassword.visible,
#password.visible:-internal-autofill-selected,
#repeatpassword.visible:-internal-autofill-selected {
}

#togglePassword, #toggleRepeatPassword, #togglePasscode {
  float: right;
  width: 25px;
  height: 25px;
  margin-top: -31px;
  margin-right: 3%;
  opacity: 0;
}
#aboutcontent, #photoreqcontent {
  max-width: 600px;
}

#wait {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(100, 100, 100, 0.4) !important;
}

#camera {
  transform: scaleX(-1);
}

.fullheight {
  height: 100%;
}

.fullwidth {
  width: 100%;
}

.extralineheight {
  line-height: 150%;
}

.uppercase {
  text-transform: uppercase;
}

.selectedfont {
  text-decoration: underline;
}

.ontop {
  z-index: 999;
}

#scrolltotop {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index:999;
  background: rgba(0, 0, 0, 0.3);
}

#scrolltobottom {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index:999;
  background: rgba(0, 0, 0, 0.3)
}
